<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">操作提醒</template>
        <el-alert
            title="满减活动列表展示商品的满减活动相关信息"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="可根据满减活动名称搜索出具体信息"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="点击详情按钮,查看活动详情信息"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="进行中的活动需先关闭才可进行删除操作"
            type="warning"
            :closable="false">
        </el-alert>
        <el-alert
            title="时间超过活动结束时间时,活动自动结束"
            type="warning"
            :closable="false">
        </el-alert>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel :span="24">
      <el-form class="button" :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="活动名称:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入活动名称"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
          <el-button style="margin-right: 10px" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
            添加活动
          </el-button>
        </el-form-item>
      </el-form>
      <el-button-group class="button">
        <button type="button" class="tab_button"
                v-for="(item,index) in button_state" :key="index"
                :class="current === index ? 'tab_button1' : ''"
                @click="buttonTab(index)">
          {{ item.name }}
        </button>
      </el-button-group>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="name"
          label="活动名称">
      </el-table-column>
      <el-table-column align="center" label="开始时间" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.start_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="结束时间" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.end_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <!--    <Edit ref="Edit" />-->
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
// import Edit from './components/Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {},
  props: [],
  data() {
    return {
      activeName: '',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      current: 0,
      button_state: [
        {
          name: '全部'
        },
        {
          name: '进行中'
        },
        {
          name: '已结束'
        },
        {
          name: '已关闭'
        },
        {
          name: '已开始'
        },
      ],
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
            {name: '下午'},
          ],
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
          ],
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
          ],
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.current = 1;
        this.queryForm.type = 1
      } else if (num == 2) {
        this.current = 2;
        this.queryForm.type = 2
      } else if (num == 3) {
        this.current = 3;
        this.queryForm.type = 3
      } else if (num == 4) {
        this.current = 4;
        this.queryForm.type = 4
      } else {
        this.current = 0;
        this.queryForm.type = ''
      }
      this.fetchData()
    },
    handleEdit(row) {
      if (row.id) {
        // this.$refs['Edit'].showEdit(row)
        this.$router.push({
          path: 'fullMinus/addReduction',
          query: {
            id: row.id,
          }
        })
      } else {
        this.$router.push({
          path: 'fullMinus/addReduction',
          query: {
            id: '',
          }
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      const {list} = data
      for (let i = 0; i < list.length; i++) {
        list[i]['state'] = list[i]['state'].toString()
      }
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '进行中'
          : row.state == '1' ? '已结束'
              : row.state == '2' ? '已关闭'
                  : row.state == '2' ? '未结束'
                      : '暂无'
    },
  }
}

</script>
<style>
.tab_button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 9px 15px;
  font-size: 13px;
  border-radius: 0;
}

.tab_button1 {
  background: #409eff;
  color: #f0f0f0;
}

.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
